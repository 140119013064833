import React from 'react';
import {Box, Container, Typography, Tab, Tabs} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/styles';
import {FAQNuevo} from 'src/templates';

// Imports para los contenidos
import { getContent } from 'src/queries';

export default function TerminosYRegulaciones() {
 const theme = useTheme();
 const sm = useMediaQuery(theme.breakpoints.down('md'));
 const [value, setValue] = React.useState(0);

 const termsConditionsResults = process.env.GATSBY_REACT_APP_REGION !== "es-MX" ? getContent("terms-conditions", process.env.GATSBY_REACT_APP_REGION) : null
 const privacyPolicyResults = process.env.GATSBY_REACT_APP_REGION !== "es-MX" ? getContent("privacy-policy", process.env.GATSBY_REACT_APP_REGION) : null
 const cookiesPolicyResults = process.env.GATSBY_REACT_APP_REGION !== "es-MX" ? getContent("cookies-policy", process.env.GATSBY_REACT_APP_REGION) : null

 const style = {
  tab: {
   color: `${theme.palette.secondary.main}`,
   '&.Mui-selected': {
    color: theme.palette.secondary.dark
   }
  }
 }

 const handleChange = (event, newValue) => {
  setValue(newValue);
 };

 function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
   <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
   >
    {value === index && (
     <Box sx={{ p: 3 }}>
      <Typography>{children}</Typography>
     </Box>
    )}
   </div>
  );
 }

 const Cabecera = () => {
  return <Box sx={{
   display: "flex",
   flexDirection: "row",
   alignItems: "flex-end",
   justifyContent: "center",
   color: 'text.secondary',
   minHeight: sm ? '92px' : '99px',
   height: '100%',
   padding: "36px 23px"
  }}/>
 };

 function a11yProps(index) {
  return {
   id: `simple-tab-${index}`,
   'aria-controls': `simple-tabpanel-${index}`,
  };
 }

 return (
  <Box sx={{ background: `${theme.palette.primary.main}20` }}>
   <Cabecera/>
   <Container>
    <Box sx={{ maxWidth: "1100px", margin: "0 auto"}}>
     <Box sx={{ padding: "72px 0"}}>
      <Box sx={{ marginBottom: "32px"}}>
       <Typography variant="h3" color="primary.dark">Términos y regulaciones</Typography>
      </Box>

      <Box sx={{ width: '100%' }}>
       <Box sx={{ mb: 4 }}>
        <Tabs 
         value={value}
         onChange={handleChange}
         aria-label="basic tabs example"
         centered
         TabIndicatorProps={{ sx: { background: theme.palette.secondary.dark }}}
        >
          {process.env.GATSBY_REACT_APP_REGION === "es-CL" && <Tab disableRipple label="Términos y condiciones" sx={ style.tab } {...a11yProps(0)} />}
         <Tab disableRipple label="Políticas de privacidad" sx={ style.tab } {...a11yProps(1)} />
         <Tab disableRipple label="Políticas de cookies" sx={ style.tab } {...a11yProps(2)} />
        </Tabs>
       </Box>
       {process.env.GATSBY_REACT_APP_REGION === "es-CL" && (
       <TabPanel value={value} index={0}>
        <FAQNuevo 
          preguntas={termsConditionsResults}
          />
       </TabPanel>
       )}
       {process.env.GATSBY_REACT_APP_REGION !== "es-MX" && (
      <>
         <TabPanel value={process.env.GATSBY_REACT_APP_REGION === "es-CL" ? value : value + 1} index={1}>
            <FAQNuevo 
            preguntas={privacyPolicyResults}
            />
         </TabPanel>
         <TabPanel value={process.env.GATSBY_REACT_APP_REGION === "es-CL" ? value : value + 1} index={2}>
            <FAQNuevo 
            preguntas={cookiesPolicyResults}
            />
         </TabPanel>
       </> )}
      </Box>

     </Box>
    </Box>
   </Container>
  </Box>);
};
